catch-callout, catch-payment-method {
    --accent-color: #111;
    --redeeming-accent-color: #111; 
    color: #111;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    -webkit-font-smoothing: auto;
}

.callout-disabled,
.catch-tab-hidden,
.catch-hidden {
    display: none;
}

#catch-purchase-widget,
.callout-mini-cart-wrapper {
    margin-bottom: 0.625rem;
}

#catch-payment-widget {
    margin: 0 auto;
}

.catch-payment-widget-container {
    position: absolute;
    display: inline-block;
    align-items: center;
    justify-content: center;
}

// stylelint-disable selector-type-no-unknown
.callout-mini-cart-wrapper > catch-callout,
.callout-checkout-wrapper > catch-callout,
.catch-payment-widget-container > catch-payment-method {
    width: auto;
}
